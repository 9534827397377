body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  list-style-type: none;
  text-decoration: none;
}
.ul {
  display: flex;
  justify-content: space-evenly;
}

li {
  font-size: 1.5rem;
  padding-left: 1rem;
}

.ul>li> a {
  color: #fff;
}

.fa{
  background: "#FDBC18" 
}